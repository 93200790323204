import '../../theme/datepicker.css'

import styled from '@emotion/styled'
import { Checkbox } from 'app/components/Common/Checkbox'
import { FormMessages } from 'app/components/Common/FormMessages'
import { Input } from 'app/components/Common/Input'
import { Select } from 'app/components/Common/Select'
import { Spinner } from 'app/components/Common/Spinner'
import { Textarea } from 'app/components/Common/Textarea'
import { Brand } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import {
  FormData,
  WeddingsFormSenderBackend,
} from 'app/utils/WeddingsFormSender'
import { de, enUS, es, fr, it } from 'date-fns/esm/locale'
import { rgba } from 'emotion-rgba'
import { isLeft } from 'fp-ts/lib/These'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import { useForm } from 'react-hook-form'

registerLocale('de', de)
registerLocale('en', enUS)
registerLocale('es', es)
registerLocale('fr', fr)
registerLocale('it', it)

const EMAIL_REGEX = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i
const DATE_OPTIONS = {
  year: 'numeric',
  month: '2-digit',
  day: 'numeric',
}

export interface Props {
  languageCode: string
  languagePrefix?: string | null
  onWeddingsFormSendToBackend: WeddingsFormSenderBackend
  title?: string
}

export const WeddingsForm = memo(function WeddingsForm({
  languageCode,
  languagePrefix,
  onWeddingsFormSendToBackend,
  title,
}: Props) {
  /*
   * Datepicker configuration
   */
  const [date, setDate] = useState<Date>(new Date())
  const [dateValue, setDateValue] = useState('')

  /*
   * Form data
   */
  const [isSendingForm, setIsSendingForm] = useState(false)
  const [displaySendErrorFeedback, setDisplaySendErrorFeedback] =
    useState(false)
  const [displaySendConfirmFeedback, setDisplaySendConfirmFeedback] =
    useState(false)

  const onValidSubmission = useCallback(
    async (data: FormData) => {
      if (isSendingForm) {
        return
      }

      setDisplaySendErrorFeedback(false)
      setDisplaySendConfirmFeedback(false)
      setIsSendingForm(true)
      const sendResult = await onWeddingsFormSendToBackend(data)
      setIsSendingForm(false)

      if (isLeft(sendResult)) {
        setDisplaySendErrorFeedback(true)
      } else {
        setDisplaySendConfirmFeedback(true)

        typeof window.gtag !== 'undefined' &&
          window.gtag('event', 'Submit', {
            event_category: 'Website',
            event_label: 'Weddings Form',
          })
      }
    },
    [isSendingForm, onWeddingsFormSendToBackend],
  )

  const { register, formState, handleSubmit, setValue } = useForm<FormData>({
    mode: 'onBlur',
    shouldFocusError: true,
  })

  const onSubmit = useMemo(
    () => handleSubmit(onValidSubmission),
    [handleSubmit, onValidSubmission],
  )

  /**
   * Handle scrolling to first field with errors
   */

  useEffect(() => {
    if (formState.errors) {
      const firstErrorElement = Object.values(formState.errors).find(
        (fieldError) => fieldError?.ref !== undefined,
      )?.ref as HTMLElement | undefined

      if (firstErrorElement && firstErrorElement.scrollIntoView) {
        firstErrorElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    }

    if (dateValue) {
      setValue('date', dateValue)
    }
  }, [formState.errors, dateValue])

  return (
    <Container>
      <Brand />
      {title ? <Title>{title}</Title> : null}
      <Form
        onSubmit={isSendingForm ? undefined : onSubmit}
        noValidate
        autoComplete={'off'}
      >
        <Spinner className={isSendingForm ? '' : 'hidden'} />
        <Wrapper row wrap space="between">
          <Field>
            <Label>{`${useVocabularyData('name', languageCode)}*`}</Label>
            <Input
              error={formState.errors.name !== undefined}
              isDirty={formState.dirtyFields.name}
              type="text"
              required={true}
              {...register('name', {
                required: true,
                validate: (name) => name.length > 2,
              })}
            />
          </Field>
          <Field>
            <Label>{`${useVocabularyData('lastname', languageCode)}*`}</Label>
            <Input
              error={formState.errors.lastname !== undefined}
              isDirty={formState.dirtyFields.lastname}
              type="text"
              required={true}
              {...register('lastname', {
                required: true,
                validate: (lastname) => lastname.length > 2,
              })}
            />
          </Field>
          <Field>
            <Label>{`${useVocabularyData('email', languageCode)}*`}</Label>
            <Input
              error={formState.errors.email !== undefined}
              isDirty={formState.dirtyFields.email}
              type="email"
              required={true}
              {...register('email', {
                required: true,
                validate: (email) => EMAIL_REGEX.test(email),
              })}
            />
          </Field>
          <Field>
            <Label>{`${useVocabularyData('telephone', languageCode)}*`}</Label>
            <Input
              error={formState.errors.phone !== undefined}
              isDirty={formState.dirtyFields.phone}
              type="text"
              required={true}
              {...register('phone', {
                required: true,
                validate: (phone) => phone.length > 2,
              })}
            />
          </Field>
          <Field>
            <Label>
              <Label>{`${useVocabularyData('date', languageCode)}*`}</Label>
            </Label>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              disabledKeyboardNavigation={true}
              locale={languagePrefix || 'it'}
              {...register('date', {
                required: true,
              })}
              onChange={(date) => {
                if (date) {
                  setDate(date)
                  setDateValue(
                    date.toLocaleDateString('it-IT', {
                      day: 'numeric',
                      month: '2-digit',
                      year: 'numeric',
                    }),
                  )
                }
              }}
              onFocus={(e) => (e.target.readOnly = true)}
              minDate={new Date()}
              required={true}
              selected={date}
              withPortal={
                typeof window !== 'undefined' && window.innerWidth > 767
                  ? false
                  : true
              }
            />
          </Field>
          <Field>
            <Label>{`${useVocabularyData('n-pax', languageCode)} *`}</Label>
            <Select
              error={formState.errors.pax !== undefined}
              options={[
                { value: '0 - 50' },
                { value: '50 - 100' },
                { value: '100 - 150' },
              ]}
              required={true}
              {...register('pax', {
                required: true,
                validate: (pax) => pax != '',
              })}
            />
          </Field>
          <Field className="large-field">
            <Label>{`${useVocabularyData(
              'reception-type',
              languageCode,
            )} *`}</Label>
            <Select
              error={formState.errors.reception_type !== undefined}
              options={[
                { value: useVocabularyData('lunch', languageCode) },
                { value: useVocabularyData('dinner', languageCode) },
              ]}
              required={true}
              {...register('reception_type', {
                required: true,
                validate: (reception_type) => reception_type != '',
              })}
            />
          </Field>
          <Field className="large-field distance-field">
            <Label>{useVocabularyData('message', languageCode)}</Label>
            <Textarea required={false} {...register('message')} />
          </Field>
          <Checkbox
            error={formState.errors.privacy_policy !== undefined}
            label={useVocabularyData('form-policy-text', languageCode)}
            required={true}
            {...register('privacy_policy', {
              required: true,
            })}
          />
        </Wrapper>
        {displaySendErrorFeedback ? (
          <FormMessages
            text={useVocabularyData('form-error-message', languageCode)}
            title={useVocabularyData('form-error-message-title', languageCode)}
            type="error"
          />
        ) : null}
        {displaySendConfirmFeedback ? (
          <FormMessages
            text={useVocabularyData('form-confirmation-message', languageCode)}
            title={useVocabularyData(
              'form-confirmation-message-title',
              languageCode,
            )}
          />
        ) : null}
        <Input
          name="submit"
          type="submit"
          value={useVocabularyData('send-request', languageCode)}
          variant="submit"
        />
      </Form>
    </Container>
  )
})

const Container = styled.section`
  margin: 9rem 0;
  padding: 0 12.639vw;
  position: relative;
  text-align: center;

  > svg {
    width: auto;
    height: 4.5625rem;
    fill: ${({ theme }) => theme.colors.variants.primaryDark2};
    margin: 0 auto 1.75rem;
  }

  @media (max-width: 1199px) {
    padding: 0 5vw;
  }

  @media (max-width: 1023px) {
    margin: 4.5rem 0;
    padding: 0 1.25rem;
  }
`

const Title = styled.h3`
  max-width: 35.625rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 2.6875rem;
  margin: auto;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
`

const Form = styled.form`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  margin: 2.375rem auto 0;
  padding: 2.75rem 13.958vw 5.4375rem;
  position: relative;
  z-index: 2;

  @media (max-width: 1199px) {
    padding-right: 5vw;
    padding-left: 5vw;
  }

  @media (max-width: 1023px) {
    padding: 2.5rem 3.75rem 3.75rem;
  }

  @media (max-width: 767px) {
    padding: 1.25rem 1.25rem 3rem;
  }
`

const Wrapper = styled(FlexBox)`
  text-align: left;

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    height: 100%;
    position: relative;
    bottom: auto;
    left: auto;
    input {
      width: 100%;
      height: 2.5rem;
      background: url('/calendar-dark.svg') no-repeat right center;
      border-bottom: 0.125rem solid
        ${({ theme }) => theme.colors.variants.neutralLight1};
      color: ${({ theme }) => theme.colors.variants.neutralDark2};
      cursor: pointer;
      font-family: ${({ theme }) => theme.fontFamily.paragraph};
      font-size: 1rem;
      padding: 0;
    }
  }

  .react-datepicker {
    font-family: ${({ theme }) => theme.fontFamily.paragraph};
    background-color: ${({ theme }) => theme.colors.variants.primaryDark2};
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
  }

  .react-datepicker__portal {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${rgba(theme.colors.variants.neutralDark1, 0.7)};
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
  }

  .react-datepicker__current-month {
    font-family: ${({ theme }) => theme.fontFamily.heading};
  }

  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day:hover,
  .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.colors.variants.primaryDark4};
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
  }

  .react-datepicker__day:focus {
    background-color: ${({ theme }) =>
      theme.colors.variants.primaryDark4} !important;
    color: ${({ theme }) => theme.colors.variants.neutralLight4} !important;
  }

  .react-datepicker__day--today {
    &:after {
      background: ${({ theme }) => theme.colors.variants.primaryDark1};
    }
  }

  .react-datepicker__day--disabled {
    color: ${({ theme }) => theme.colors.variants.neutralLight4} !important;
  }
`

const Field = styled.div`
  width: calc(50% - 1.875rem);
  margin-top: 2.5rem;
  &.large-field {
    width: 100%;
  }
  &.distance-field {
    margin-top: 3.25rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-top: 1.875rem;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 700;
  letter-spacing: 0.1625rem;
  line-height: 1.125rem;
  text-transform: uppercase;
`
